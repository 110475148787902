// 员工管理
import Q from 'q';
// 员工账号列表
import web_userBuyer_findBuyerEmployees from '@/lib/data-service/default/web_userBuyer_findBuyerEmployees';
// 修改账号状态
import web_userBuyer_updateBuyerEmployee from '@/lib/data-service/default/web_userBuyer_updateBuyerEmployee';
// 批量修改账号状态
import web_common_user_updateUserStatus from '@/lib/data-service/default/web_common_user_updateUserStatus';
// 修改账号密码
import web_common_user_updateUserPassword from '@/lib/data-service/default/web_common_user_updateUserPassword';


export default {
  components: {},
  data() {
    var validateVerify = (rule, value, callback) => {
      if (value === undefined || value === '') {
        callback(new Error("请确认密码"));
      } else {
        if (value !== this.passwordForm.password) {
          callback(new Error("两次密码不一致"));
        }
        callback();
      }
    };
    return {
      // 搜索参数
      form: {
        userName: '',
        realName: '',
        userStatus: '',
        currentPage: 1,
        pageSize: 10,
      },
      // 修改密码参数，新密码
      passwordForm: {
        password: '',
        verifyPassword: '',
      },
      passwordRules: {
        password: [
          {required: true, message: '请输入新密码', trigger: 'blur'},
          {min: 6, max: 20, message: '请设置6到20个字符', trigger: 'blur'},
          {pattern: /[0-9a-zA-Z]/, message: '仅限使用数字与字母', trigger: 'blur'},
        ],
        verifyPassword: [
          {validator: validateVerify, trigger: 'blur', required: true}
        ],
      },
      // 修改密码参数id和新密码
      changePasswordParams: {},
      // 密码弹窗
      changePasswordAdialog: false,
      allocation: {
        statusList: [
          {label: '全部', value: ''},
          {label: '启用', value: '1'},
          {label: '禁用', value: '2'},
        ],
        userList: [],
      },
      isCheckAll: false,
      // 状态弹窗
      changeStatusAdialog: false,
      changeMessage: '',
      changeParams: {},
      pager: {
        currentPage: 1,
        pageSizes: [10,20,30,40,50],
        pageSize: 10,
        totalCount: 0,
      },
    };
  },
  computed: {},
  methods: {
    // 请求员工账号列表
    req_staff_list (params) {
      const _this = this;
      Q.when()
        .then(function () {
          return web_userBuyer_findBuyerEmployees(params);
        })
        .then(function (res) {
          _this.pager.totalCount = res.pageResult.totalCount;
          let userList = res.pageResult.pageData;
          userList.forEach(function (item) {
            switch (item.userStatus) {
            case 0:
              item.userStatusText = '已删除';
              break;
              case 1:
                item.userStatusText = '启用账户';
                break;
              case 2:
                item.userStatusText = '禁用账户';
                break;
            };
            item.checked = false;
          })
          _this.allocation.userList = userList;
        })
    },
    // 请求修改员工状态
    req_change_staff_info (params) {
      return Q.when()
        .then(function () {
          return web_userBuyer_updateBuyerEmployee(params);
        })
    },
    // 请求批量修改员工状态
    req_batch_change_staff_info (params) {
      return Q.when()
        .then(function () {
          return web_common_user_updateUserStatus(params);
        })
    },
    // 请求修改密码
    req_change_password (params) {
      return Q.when()
        .then(function () {
          return web_common_user_updateUserPassword(params);
        })
    },
    initialize () {
      this.req_staff_list(this.form);
    },
    search_staff () {
      this.form.currentPage = 1;
      this.req_staff_list(this.form);
    },
    reset_searchParams () {
      this.form = {
        userName: '',
        realName: '',
        userStatus: '',
      }
      this.form.userName = '';
      this.form.realName = '';
      this.form.userStatus = '';
    },
    handleSizeChange (pages) {
      this.form.pageSize = pages;
      this.pager.pageSize = pages;
      this.req_staff_list(this.form);
    },
    handleCurrentChange (page) {
      this.form.currentPage = page;
      this.pager.currentPage = page;
      this.req_staff_list(this.form);
      document.documentElement.scrollTop = 0;
    },
    to_add_staff () {
      this.$router.push({
        name: 'distributor-tour-back-add-staff',
      })
    },
    to_change_staff (id) {
      this.$router.push({
        name: 'distributor-tour-back-change-staff',
        query: {id}
      })
    },
    change_staff_status (id, userStatus) {
      switch (userStatus) {
        case 0:
          this.changeMessage = '确定删除此账号吗';
          break;
        case 1:
          this.changeMessage = '确定启用此账号吗';
          break;
        case 2:
          this.changeMessage = '确定禁用此账号吗'
      }
      this.changeStatusAdialog = true;
      this.changeParams = {id, userStatus};
    },
    batch_change_staff_status (userStatus) {
      let ids = '';
      this.allocation.userList.forEach(function (item) {
        if (item.checked) {
          if (ids) {
            ids += `,${item.id}`;
          } else {
            ids = item.id;
          }
        }
      })

      if (ids) {
        this.changeParams = {ids, userStatus};
        switch (userStatus) {
          case 0:
            this.changeMessage = '确定删除吗';
            break;
          case 1:
            this.changeMessage = '确定启用吗';
            break;
          case 2:
            this.changeMessage = '确定禁用吗'
        }
        this.changeStatusAdialog = true;
      } else {
        switch (userStatus) {
          case 0:
            this.changeMessage = '请选择要删除的员工';
            break;
          case 1:
            this.changeMessage = '请选择要启用的员工';
            break;
          case 2:
            this.changeMessage = '请选择要禁用的员工'
        }
        this.changeStatusAdialog = true;
      }
    },
    handleClose () {
      this.changeParams = {};
      this.changeStatusAdialog = false;
    },
    commit_changeStatus () {
      const _this = this;
      Q.when()
        .then(function () {
          if (_this.changeParams.id) {
            return _this.req_change_staff_info(_this.changeParams);
          } else if (_this.changeParams.ids) {
            return _this.req_batch_change_staff_info(_this.changeParams);
          }
        })
        .then(function () {
          _this.changeParams = {};
          _this.changeStatusAdialog = false;
          _this.req_staff_list(_this.form);
        })
    },
    cancel_changeStatus () {
      this.changeParams = {};
      this.changeStatusAdialog = false;
    },
    to_change_password (id) {
      this.changePasswordParams.id = id;
      this.changePasswordAdialog = true;
    },
    handleClosePassword () {
      this.changePasswordParams = {};
      this.changePasswordAdialog = false;
    },
    commit_password (form) {
      const _this = this;
      this.$refs[form].validate((valid) => {
        if (valid) {
          _this.changePasswordParams.password = _this.passwordForm.password;
          Q.when()
            .then(function () {
              return _this.req_change_password(_this.changePasswordParams);
            })
            .then(function () {
              _this.$message({
                message: '密码修改成功',
                type: 'success'
              });
              _this.handleClosePassword();
            })
        } else {
          return false;
        }
      })
    },
    cancel_password () {
      this.handleClosePassword();
    }
  },
  watch: {
    isCheckAll: function (newVal) {
      this.allocation.userList.forEach(function (item) {
        item.checked = newVal;
      })
    }
  },
  mounted() {
    
  },
  activated() {
    this.initialize();
  }
};
